@font-face {
  font-family: Roboto-Bold;
  src: url(./assets/fonts/Roboto-Bold.ttf);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Roboto-Italic;
  src: url(./assets/fonts/Roboto-Italic.ttf);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(./assets/fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(./assets/fonts/Roboto-Regular.ttf);
}

h3 {
  font-size: 20px;
}
h4,
h5,
p,
a,
button,
body {
  font-size: 12px !important;
}

.form-control {
  font-size: 12px;
}
